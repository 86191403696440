import React from "react";
import styled from "styled-components";

const Link = styled.a`
  display: flex;
  background: #ff0;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  color: #000;

  transition: color 0.3s, background-color 0.3s;

  text-decoration: none !important; 

  &:active,
  &:focus {
    color: #fff !important;
    background-color: #000;
  }

  @media (any-pointer: fine) {
    &:hover {
      color: #fff !important;
      background-color: #000;
    }
  }

  ${(props) =>
    props.theme.apply(
      "default",
      (breakpoint: string) => {
        return `
          ${props.theme.textStyle(breakpoint, "h3Heading")};
          width: ${props.theme.spacePx(breakpoint, 3)};
          height: ${props.theme.spacePx(breakpoint, 3)};
        `;
      }
    )}
`;

export const Coin = () => {
  return (
    <Link href="https://www.ikon-gallery.org/make-a-donation" target="_blank" rel="nofollow noreferrer">
      DONATE
    </Link>
  );
};
