import styled from "styled-components";

export const FormFields = styled.div`
  .form-field {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  input {
    font-family: "ABC Diatype", Arial, Helvetica, sans-serif;
    border: 1px solid #000;
    background: #fff;
    position: relative;
    appearance: none;
    width: 100%;
    border-radius: 0;

    &:focus {
      box-shadow: 0 0 0 1px #000;
    }
  }

  input[type="checkbox"] {
    margin: 2px;
    border: 0;
    padding: 0;
    border: 1px solid #000;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      top: 1px;
      left: 1px;
      background-color: #fff;
    }

    &:checked {
      &:after {
        background-color: #000;
      }
    }
  }

  input[type="radio"] {
    margin: 2px;
    border: 0;
    padding: 0;
    border: 1px solid #000;
    border-radius: 100px;
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      top: 1px;
      left: 1px;
      border-radius: 100px;
      background-color: #fff;
    }

    &:checked {
      &:after {
        background-color: #000;
      }
    }
  }

  textarea {
    font-family: "ABC Diatype", Arial, Helvetica, sans-serif;
    border: 1px solid #000;
    background: #fff;
    position: relative;
    appearance: none;
    width: 100%;
    border-radius: 0;

    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 1px #000;
      border: 1px solid #000;
      border-radius: 0;
      outline: none;
    }
  }

  .error {
    input,
    textarea {
      &:invalid,
      &[aria-invalid="true"] {
        border-color: var(--ikon-error-color, #c00);
        box-shadow: 0 0 0 1px var(--ikon-error-color, #c00);
        &:focus {
          border-color: #000;
          box-shadow: 0 0 0 1px #000;
        }
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      &:invalid,
      &[aria-invalid="true"] {
        border-color: var(--ikon-error-color, #c00);

        &:checked {
          background-color: var(--ikon-error-color, #c00);
        }
        &:focus {
          box-shadow: 0 0 0 1px var(--ikon-error-color, #c00);
          outline: none;
        }
      }
    }
  }

  button {
    font-family: ABC Diatype,Arial,Helvetica,sans-serif;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    position: relative;
    appearance: none;
    color: #000;
    background-color: var(--ikon-hl-color, #ff0);
    transition: color 0.3s, background-color 0.3s;

    @media (any-pointer: fine) {
      &:hover {
        color: #fff !important;
        background-color: #000 !important;
      }
    }

    &:disabled {
      background-color: #555;
      color: #333;
    }

    &.back {
      background-color: #ccc;
    }
    &[type="submit"],
    &.back {
      padding: 0 1em;
      display: flex;
      align-items: center;

      &.loading {
        background-color: #555;
      }
    }
  }

  label,
  .group-label  {
    display: flex;
    align-items: flex-end;

    abbr {
      margin-left: 0.2em;
    }
  }

  .error-message {
    display: flex;
    color: var(--ikon-error-color, #c00);
  }

  .checkbox:not(.form-field),
  .radio:not(.form-field) {
    display: grid;
    width: 100%;
    grid-template-columns: auto 1fr;

    label {
      flex-shrink: 0;
      flex-grow: 0;
      height: auto;
      margin: 0;
      cursor: pointer;
      padding-left: 0.3em;
    }
  }

  label:empty,
  .group-label:empty {
    display: none;
  }

  .form-error {
    color: var(--ikon-error-color, #c00);
  }

  .form-field.consent {
    .checkboxes {
      display: flex;
      flex-wrap: wrap;

      label:first-of-type {
        width: 100%;
      }

      .gfield_consent_label {
        height: auto;
        display: inline-block;
        cursor: pointer;
        padding-left: 0.5em;
      }
    }

    &.error {
      .gfield_consent_label {
        color: var(--ikon-error-color, #c00);
      }
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        .form-error {
          ${props.theme.textStyle(breakpoint, `h4Heading`)};
          padding-top: ${props.theme.spacePx(breakpoint, 5)};
          margin-bottom: ${props.theme.spacePx(breakpoint, 6)};
        }

        .form-field {
          column-gap: ${props.theme.spacePx(breakpoint, 7)}; 
        }

        .description {
          padding-top: ${props.theme.spacePx(breakpoint, 10)};
          ${props.theme.textStyle(breakpoint, `caption`)};
        }

        button {
          &[type="submit"],
          &.back {
        
            ${props.theme.textStyle(breakpoint, `h3Heading`)};
            height: ${props.theme.spacePx(breakpoint, 6)};
            max-width: ${props.theme.spacePx(breakpoint, 2)};
          }
        }

        textarea {
          height: ${props.theme.spacePx(breakpoint, 4)};       
          padding: ${props.theme.spacePx(breakpoint, 10)};
          ${props.theme.textStyle(breakpoint, `body`)};
        }

        .form-confirmation {
          ${props.theme.textStyle(breakpoint, `h3`)};
        }
       
        input  {
          ${props.theme.textStyle(breakpoint, `h3`)};
          padding: 0.1em ${props.theme.spacePx(
            breakpoint,
            10
          )} 0 ${props.theme.spacePx(breakpoint, 10)};
          font-weight: 400;
          height: ${props.theme.spacePx(breakpoint, 6)};     
        }

        .error-message {
          ${props.theme.textStyle(breakpoint, `h4Heading`)};
          height: ${(
            props.theme.space(breakpoint, 8) + props.theme.space(breakpoint, 9)
          ).toFixed(2)}px;
          padding-top: calc(${props.theme.spacePx(
            breakpoint,
            9
          )} + ${props.theme.spaceFontTop(breakpoint, `h4Heading`)});
        }

        label,
        .group-label {
          min-height: ${props.theme.spacePx(breakpoint, 6)};
          ${props.theme.textStyle(breakpoint, `h4Heading`)};     
          margin-bottom: ${props.theme.spaceFontBottom(
            breakpoint,
            9,
            `h4Heading`
          )};
          padding-top: ${props.theme.spacePx(breakpoint, 9)};
        }

        input[type="checkbox"],
        input[type="radio"] {
          width: ${props.theme.spacePx(breakpoint, 8)};
          height: ${props.theme.spacePx(breakpoint, 8)};
        }
        
        .checkbox:not(.form-field),
        .radio:not(.form-field) {
          padding-bottom: ${props.theme.spacePx(breakpoint, 9)};
          label {
            
            ${props.theme.textStyle(breakpoint, `body`)};
            text-transform: none;
            min-height: auto;
            padding-top: 0;
          }
        }

        .form-field.consent {
          .gfield_consent_label {
            width: calc(100% - 5px - ${props.theme.spacePx(breakpoint, 8)});
            padding-top:0;
            min-height: auto;
          }
          .description {
            padding-top: 0;
          }
        }
        `;
    })}
`;
