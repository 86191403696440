import React from "react";
import styled from "styled-components";
import { useSettingsContext } from "~/providers/SettingsContextProvider";
import { MenuItem } from "./MenuItem";

const Nav = styled.nav`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.theme.apply(
      "default",
      (breakpoint: string) => {
        return `
          ${props.theme.textStyle(breakpoint, "h3Heading")};
          & > span {
            margin-top: ${props.theme.spaceFontTop(breakpoint, "h3Heading")};
            margin-bottom: ${props.theme.spaceFontBottom(breakpoint, ["desktop", "screen"].includes(breakpoint) ? 8 : 7, "h3Heading")};
          }
        `;
      }
    )}
`;

export const MenuFooter = ({ id }: { id: string }) => {
  const settings = useSettingsContext();

  return (
    <Nav>
      {!settings?.menus?.footer?.items?.length && (
        <b>
          No menu items in menu &quot;{settings?.menus?.footer?.name ?? id}
          &quot; added
        </b>
      )}
      {settings?.menus?.footer?.items?.length > 0 &&
        settings?.menus?.footer?.items?.map((item: any, index: number) => (
          <MenuItem key={`${id}-${index}`} item={item} />
        ))}
    </Nav>
  );
};
