import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import FocusLock from "react-focus-lock";

import { useMenuContext } from "~/providers/MenuContextProvider";
import { useSettingsContext } from "~/providers/SettingsContextProvider";
import { Logo } from "../ui/Logo";
import { SvgBackground } from "../ui/SvgBackground";
import { MenuMain } from "./Menus/MenuMain";
import { SocialMediaIcons } from "../ui/SocialMediaIcons";
import Button from "../styled/Button";
import InputText from "../styled/InputText";
import { MenuPages } from "./Menus/MenuPages";
import { useRouter } from "next/router";

const MenuContainer = styled.div<{
  isOpen?: boolean;
}>`
  position: fixed;
  z-index: 1000;
  top: 0px;
  width: 100vw;
  background-color: var(--ikon-hl-color, #ff0);
  transform: ${({ isOpen }) => (isOpen ? `none` : "translateX(-105%)")};
  transition: transform 0.3s;
  overflow: hidden;
  & a {
    text-decoration: none;
  }
  & *::selection {
    background: var(--ikon-bg-color, #fff);
  }

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    width: 66.66vw;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    width: 50vw;
    min-width: 800px;
  }
`;
const MenuWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const MenuContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  position: sticky;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  width: 100%;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          padding: 0 ${props.theme.marginPx(breakpoint)};
        `;
    })}
`;

const MenuContentGrid = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  position: sticky;
  height: 50px;
  background: var(--ikon-hl-color, #ff0);
  top: 0px;
  z-index: 2;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          padding: 0 ${props.theme.marginPx(breakpoint)};
          height: ${props.theme.spacePx(breakpoint, 5)};
        `;
    })}
`;

const HeaderContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000;
`;

const Footer = styled.div`
  width: 100%;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          padding-bottom: ${props.theme.spacePx(breakpoint, 7)};
        `;
    })}
`;

const QuickSearch = styled.div`
  width: 100%;
  border-bottom: 1px solid #000;
  overflow: hidden;
  position: absolute;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        width: calc(100% - ${(props.theme.margin(breakpoint) * 2).toFixed(
          2
        )}px);
        height: ${props.theme.spacePx(breakpoint, 6)};
        ${props.theme.textStyle(breakpoint, "h3Heading")};
      `;
    })}
`;

const MenuButton = styled(Button)`
  & > span {
    transition: filter 0.3s;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:active,
  &:focus-within {
    & > span {
      filter: invert(33.33%);
    }
  }

  @media (any-pointer: fine) {
    &:hover {
      & > span {
        filter: invert(33.33%);
      }
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
      height: ${(
        2 +
        props.theme.space(
          breakpoint,
          ["base", "mobile"].includes(breakpoint) ? 7 : 8
        )
      ).toFixed(4)}px;
      width: ${(
        2 +
        props.theme.space(
          breakpoint,
          ["base", "mobile"].includes(breakpoint) ? 7 : 8
        )
      ).toFixed(4)}px;
      
    `;
    })}
`;

const Navigations = styled.div`
  grid-area: navigation;
  display: grid;
  grid-template-columns: auto 1fr;

  /* Confirm TODO that the precise 50%/50% split is to be set at landscape*/
  ${({ theme }) => theme.breakpoints.mobile} {
    grid-template-columns: 1fr 1fr;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          column-gap: ${props.theme.gutterPx(breakpoint)};
          padding-top: ${(
            props.theme.space(breakpoint, 6) + props.theme.space(breakpoint, 8)
          ).toFixed(2)}px;
          min-height: ${props.theme.spacePx(
            breakpoint,
            ["base", "mobile"].includes(breakpoint) ? 1 : 2
          )};
          padding-bottom: calc(
            ${props.theme.spacePx(breakpoint, 7)}
          );
        `;
    })}
`;

const Screen = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 999;
`;

const Address = styled.div`
  & p {
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          margin-bottom: ${props.theme.spacePx(breakpoint, 7)};
        `;
    })}
`;

const Search = styled(InputText)<{ isError: boolean }>`
  width: 100%;
  height: 100%;
  border: none;
  background: var(--ikon-hl-color, #ff0);
  color: ${({ isError }) => (isError ? "#f00" : "#000")};

  vertical-align: top;
  &::placeholder {
    color: #000 !important;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${props.theme.textStyle(breakpoint, `h3Heading`)};
        padding-top: ${props.theme.fontValignPx(breakpoint, "h3Heading")};
        `;
    })}
`;

const SearchButton = styled(Button)<{ active: boolean }>`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: filter 0.3s, opacity 0.3s;

  & > span {
    transition: filter 0.3s;
  }
  &:active,
  &:focus-within {
    & > span {
      filter: invert(33.33%);
    }
  }

  @media (any-pointer: fine) {
    &:hover {
      & > span {
        filter: invert(33.33%);
      }
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${props.theme.textStyle(breakpoint, `h2Heading`)};
        width: ${props.theme.spacePx(breakpoint, 7)};
        height: ${props.theme.spacePx(breakpoint, 7)};
        `;
    })}
`;

export const Menu = () => {
  const settings = useSettingsContext();
  const router = useRouter();
  const { isOpen, close } = useMenuContext();

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const menuContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const menuContentRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [isError, setIsError] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const onResize = useCallback(() => {
    if (menuContainerRef.current)
      menuContainerRef.current.style.height = `${window.innerHeight + 200}px`;

    if (menuContentRef.current)
      menuContentRef.current.style.height = `${window.innerHeight}px`;
  }, []);
  const onResizeDebounced = debounce(onResize, 100);

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("resize", onResizeDebounced);

    onResize();

    const triggerOnResize = () => {
      onResize();
    };
    document.addEventListener("DOMContentLoaded", triggerOnResize);

    return () => {
      window.removeEventListener("resize", onResizeDebounced);
      document.removeEventListener("DOMContentLoaded", triggerOnResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Screen
        onClick={() => {
          close();
        }}
        style={{ display: isOpen ? "block" : "none" }}
      />
      <FocusLock
        disabled={!isOpen}        
      >
        <MenuContainer ref={menuContainerRef} isOpen={isOpen}
          id="menu"
          aria-hidden={!isOpen}
          role="menu"
          tabIndex={!isOpen ? -1 : undefined}
        >
          <MenuWrapper>
            <MenuContent ref={menuContentRef}>
              <MenuContentGrid>
                <Header>
                  <HeaderContent>
                    <Logo />
                    <MenuButton
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        // close() will return focus to menu button
                        close();
                      }}

                      aria-expanded={isOpen}
                      aria-label={`${isOpen ? "close" : "open"} menu`}
                      aria-controls="menu"
                    >
                      <SvgBackground
                        className="svg close"
                        type="close"
                        position="left center"
                        width="100%"
                        height="100%"
                      />
                    </MenuButton>
                  </HeaderContent>
                </Header>
                <Content>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();

                      if ((inputRef?.current?.value?.trim()?.length ?? 0) > 2) {
                        router.push(
                          `/search?s=${inputRef?.current?.value?.trim()}`
                        );
                        setTimeout(() => {
                          if (inputRef?.current) inputRef.current.value = "";
                        }, 200);
                      } else {
                        setIsError(true);
                        setIsActive(false);
                      }
                    }}
                  >
                    <QuickSearch>
                      <Search
                        placeholder="SEARCH"
                        isError={isError}
                        ref={inputRef}
                        onChange={() => {
                          setIsError(false);
                          if (
                            (inputRef?.current?.value?.trim()?.length ?? 0) > 2
                          ) {
                            setIsActive(true);
                          } else {
                            setIsError(false);
                            setIsActive(false);
                          }
                        }}
                      />
                      <SearchButton
                        aria-label="search"
                        active={isActive}
                        tabIndex={!isActive ? -1 : undefined}
                      >
                        <SvgBackground
                          type="arrow"
                          position="center center"
                          width="100%"
                          height="100%"
                        />
                      </SearchButton>
                    </QuickSearch>
                  </form>
                  <Navigations>
                    <MenuMain id="menu-main" />
                    <MenuPages id="menu-pages" />
                  </Navigations>
                </Content>
                <Footer>
                  <Content>
                    <Address
                      dangerouslySetInnerHTML={{
                        __html: settings?.options?.menuContactInformation ?? "",
                      }}
                    />
                    <SocialMediaIcons />
                  </Content>
                </Footer>
              </MenuContentGrid>
            </MenuContent>
          </MenuWrapper>
        </MenuContainer>
      </FocusLock>
    </>
  );
};
