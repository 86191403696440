import React from "react";
import styled from "styled-components";

export const PageMargins = styled.div<{
  spaceTop?: number;
  spaceBottom?: number;
  spaceFillAvailable?: boolean;
  spaceMinHeight?: number;
  position?: string;
}>`
  display: flow-root;
  min-width: 100%;
  position: ${({position}) => position ?? "static"};
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        min-height: ${
          props.spaceMinHeight && !props.spaceFillAvailable
            ? `${(props.theme.space(breakpoint, props.spaceMinHeight) - (props.spaceBottom ? props.theme.space(breakpoint, props.spaceBottom) : 0)).toFixed(0)}px`
            : props.spaceFillAvailable
            ? `calc(100vh - ${props.theme.spacePx(breakpoint, 4)})`
            : "auto"
        };
        
        padding: ${
          props.spaceTop ? props.theme.spacePx(breakpoint, props.spaceTop) : 0
        } ${props.theme.marginPx(breakpoint)} ${
        props.spaceBottom
          ? props.theme.spacePx(breakpoint, props.spaceBottom)
          : 0
      } ${props.theme.marginPx(breakpoint)};
        `;
    })}
`;

export default PageMargins;
