import { singletonHook } from "react-singleton-hook";
import { breakpointEMs } from "~/theme";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import useIsMounted from "./useIsMounted";

const initialBreakpointStates = {
  currentBreakpoint: "mobile",
  isBase: true,
  isMobile: true,
  isMobilePortrait: true,
  isMobileLandscape: false,
  isTablet: false,
  isTabletAndUp: false,
  isTabletPortrait: false,
  isTabletLandscape: false,
  isTabletLandscapeAndUp: false,
  isDesktop: false,
  isDesktopAndUp: false,
  isScreen: false,
};

export const useIsBreakpointSingleton = () => {
  const breakpointsRef = useRef(initialBreakpointStates);

  const isMounted = useIsMounted();
  const [breakpoints, setBreakpoints] = useState(initialBreakpointStates);

  const onResize = useCallback(() => {
    if (!isMounted) return;

    let update = false;

    const isBase = window.matchMedia(
      `(max-width: ${breakpointEMs.mobile - 0.001}em)`
    ).matches;

    const isMobile = window.matchMedia(
      `(min-width: ${breakpointEMs.mobile}em)`
    ).matches;

    const isMobileLandscape = window.matchMedia(
      `(min-width: ${breakpointEMs.mobileLandscape}em)`
    ).matches;

    const isTablet = window.matchMedia(
      `(min-width: ${breakpointEMs.tablet}em)`
    ).matches;
    const isTabletLandscape = window.matchMedia(
      `(min-width: ${breakpointEMs.tabletLandscape}em)`
    ).matches;
    const isDesktop = window.matchMedia(
      `(min-width: ${breakpointEMs.desktop}em)`
    ).matches;
    const isScreen = window.matchMedia(
      `(min-width: ${breakpointEMs.screen}em)`
    ).matches;

    if ((isBase && !isMobile) !== breakpointsRef.current.isBase) {
      breakpointsRef.current.isBase = isBase && !isMobile;
      update = true;
    }

    if (
      ((isMobile || isMobileLandscape) && !isTablet) !==
      breakpointsRef.current.isMobile
    ) {
      breakpointsRef.current.isMobile =
        (isMobile || isMobileLandscape) && !isTablet;
      update = true;
    }

    if (isMobile !== breakpointsRef.current.isMobilePortrait) {
      breakpointsRef.current.isMobilePortrait = isMobile && !isMobileLandscape;
      update = true;
    }

    if (
      isMobileLandscape &&
      !isTablet !== breakpointsRef.current.isMobileLandscape
    ) {
      breakpointsRef.current.isMobileLandscape = isMobileLandscape && !isTablet;
      update = true;
    }

    if (
      (isTablet || isTabletLandscape) &&
      !isDesktop !== breakpointsRef.current.isTablet
    ) {
      breakpointsRef.current.isTablet =
        (isTablet || isTabletLandscape) && !isDesktop;
      update = true;
    }

    if (isTablet !== breakpointsRef.current.isTabletPortrait) {
      breakpointsRef.current.isTabletPortrait = isTablet && !isTabletLandscape;
      update = true;
    }

    if (isTabletLandscape !== breakpointsRef.current.isTabletLandscape) {
      breakpointsRef.current.isTabletLandscape =
        isTabletLandscape && !isDesktop;
      update = true;
    }

    if (isDesktop !== breakpointsRef.current.isDesktop) {
      breakpointsRef.current.isDesktop = isDesktop && !isScreen;
      update = true;
    }

    if (isScreen !== breakpointsRef.current.isScreen) {
      breakpointsRef.current.isScreen = isScreen;
      update = true;
    }

    const isDesktopAndUp = isDesktop || isScreen;
    if (isDesktopAndUp !== breakpointsRef.current.isDesktopAndUp) {
      breakpointsRef.current.isDesktopAndUp = isDesktopAndUp;
      update = true;
    }

    const isTabletLandscapeAndUp = isTabletLandscape || isDesktopAndUp;
    if (
      isTabletLandscapeAndUp !== breakpointsRef.current.isTabletLandscapeAndUp
    ) {
      breakpointsRef.current.isTabletLandscapeAndUp = isTabletLandscapeAndUp;
      update = true;
    }

    const isTabletAndUp = isTablet || isTabletLandscapeAndUp;
    if (isTabletAndUp !== breakpointsRef.current.isTabletAndUp) {
      breakpointsRef.current.isTabletAndUp = isTabletAndUp;
      update = true;
    }

    let currentBreakpoint = "base";
    if ((isMobile || isMobileLandscape) && !isTablet)
      currentBreakpoint = "mobile";
    if ((isTablet || isTabletLandscape) && !isDesktop)
      currentBreakpoint = "tablet";
    if (isDesktop && !isScreen) currentBreakpoint = "desktop";
    if (isScreen) currentBreakpoint = "screen";

    if (breakpointsRef.current.currentBreakpoint !== currentBreakpoint) {
      breakpointsRef.current.currentBreakpoint = currentBreakpoint;
      update = true;
    }

    if (update) {
      if (isMounted)
        setBreakpoints({ ...breakpointsRef.current });
    }
  }, [isMounted]);
  const onResizeDebounced = debounce(onResize, 120);

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("resize", onResizeDebounced);

    onResize();

    return () => {
      window.removeEventListener("resize", onResizeDebounced);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return breakpoints;
};

export const useIsBreakpoint = singletonHook(
  initialBreakpointStates,
  useIsBreakpointSingleton
);
