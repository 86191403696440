import React from 'react'
import styled from 'styled-components'
const A = styled.a`
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  background-color: var(--ikon-hl-color, #ff0);
  pointer-events: none;
  
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          height: ${props.theme.spacePx(breakpoint, 6)};
          width: ${props.theme.spacePx(breakpoint, 2)};
          top: ${props.theme.spacePx(breakpoint, 8)};
          left: ${props.theme.spacePx(breakpoint, 8)};
          ${props.theme.textStyle(breakpoint, `h3Heading`)};
          & > span {
            margin-top: calc(${props.theme.spaceFontTop(
              breakpoint,
              `h3Heading`
            )} * -0.25);
          }   
        `;
    })}

  .tabbed &:focus {
    transform: translateY(0);
    opacity: 1;
    
  }
`
export const SkipToLink = ({id, children}:{id:string;children:React.ReactNode}) => {
  return (
    <A href={`#${id}`}><span>{children}</span></A>
  )
}
