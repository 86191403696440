import React, { useEffect, useState, useCallback, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import debounce from "lodash/debounce";

import { SvgBackground } from "~/components/ui/SvgBackground";
import { useHeaderContext } from "~/providers/HeaderContextProvider";
import { MenuHeader } from "./Menus/MenuHeader";
import { useMenuContext } from "~/providers/MenuContextProvider";
import { Logo } from "../ui/Logo";
import { usePageStateContext } from "~/providers/PageStateContextProvider";
import { useScrollPosition } from "~/hooks/usScrollPosition";
import { useIsBreakpoint } from "~/hooks/useIsBreakpoint";
import { SkipToLink } from "../ui/SkipToLink";
import useIsMounted from "~/hooks/useIsMounted";

const loadingBorderAnimation = keyframes`
  0% { width: 100%; right: 0%; }
  33.33% { width: 0%; right: 0%; }
  66.66% { width: 0%; right: 100%; }
  100% { width: 100%; right: 0%; }
`;

const StyledHeader = styled.header<{
  headerTransform?: string;
  headerPosition?: string;
  headerColor?: string;
  isOverlay?: boolean;
  isHidden?: boolean;
}>`
  position: ${({ headerPosition }) => headerPosition ?? "sticky"};
  background-color: ${({ headerColor }) => headerColor ?? "transparent"};
  top: 0;
  left: 0;
  width: 100%;

  z-index: ${({ theme }) => theme.zIndex.menu};
  transform: ${({ headerTransform }) => headerTransform};
  transition: background-color 0.3s, transform 0.5s, opacity 0.5s;

  & a {
    text-decoration: none;
  }
  
  ${({ isOverlay }) =>
    isOverlay
      ? css`
          background-color: transparent;
          & > nav {
            filter: invert(100%);
            transition: filter 0.3s;
          }

          @media (any-pointer: fine) {
            &:hover {
              & > nav {
                filter: invert(0%);
              }
              background-color: var(--ikon-bg-color, #fff);
            }
          }
        `
      : undefined}

  ${({ isHidden }) =>
    isHidden
      ? `
          pointer-events: none;
          opacity: 0;
          touch-action: none;
        `
      : undefined}
`;

const MainNav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateZ(0);
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          padding: 0 ${props.theme.marginPx(breakpoint)};
          height: ${props.theme.spacePx(breakpoint, 5)};
        `;
    })}
`;

const SubMenu = styled.div<{
  visible?: boolean;
}>`
  margin-top: 1px;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          min-height: ${(props.theme.space(breakpoint, 6) + 1).toFixed(2)}px;
          padding: 0 ${props.theme.marginPx(breakpoint)};
        `;
    })}
`;

const BorderContainer = styled.div<{
  isLoading?: boolean;
  borderColor?: string;
  alignItems?: string;
  minHeightSize?: number;
}>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems ?? "center"};
  width: 100%;
  height: calc(100% + 1px);
  position: relative;
  padding-bottom: 1px;
  
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        min-height: ${(props.theme.space(breakpoint, 6) + 1).toFixed(2)}px;
        `;
    })}

  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: ${({ borderColor }) => borderColor ?? "#000"};
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 31 ;
    animation: ${({ isLoading }) =>
      isLoading
        ? css`
            ${loadingBorderAnimation} 2s infinite
          `
        : "none"};
  }
`;

const HeaderNav = styled.div`
  display: flex;
  height: 100%;
`;

const HeaderNavLinks = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
      transform: translateY(${props.theme.fontValignPx(
        breakpoint,
        "h3Heading"
      )});
        
      `;
    })}
`;

const HeaderNavButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const MenuButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  position: relative;

  & > span {
    transition: filter 0.3s;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:active,
  &:focus-within {
    & > span {
      filter: invert(33.33%);
    }
  }

  @media (any-pointer: fine) {
    &:hover {
      & > span {
        filter: invert(33.33%);
      }
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
      transform: translateY(calc(${props.theme.fontValignPx(
        breakpoint,
        "h3Heading"
      )} * -0.5));
      height: ${(
        2 +
        props.theme.space(
          breakpoint,
          ["base", "mobile"].includes(breakpoint) ? 7 : 8
        )
      ).toFixed(4)}px;
      width: ${(
        2 +
        props.theme.space(
          breakpoint,
          ["base", "mobile"].includes(breakpoint) ? 7 : 8
        )
      ).toFixed(4)}px;
      
    `;
    })}
`;

const scrollUpPixel = 200;
const firstSectionOffset = 0;

export const Header = ({
  isHome,
  hasHero,
  isOverlay,
  children,
  isHidden,
  showMainMenu = true,
}: {
  children?: React.ReactNode;
  isOverlay?: boolean;
  hasHero?: boolean;
  isHome?: boolean;
  showMainMenu?: boolean;
  isHidden?: boolean;
}) => {
  const isMounted = useIsMounted();
  const menuContext = useMenuContext();
  const { isTabletLandscapeAndUp } = useIsBreakpoint();

  const scrollUpCounterTimeoutRef = useRef<ReturnType<
    typeof setTimeout
  > | null>(null);

  const headerContext = useHeaderContext();
  const { isLoading } = usePageStateContext();

  const scrollUpHideTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const animatingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  const scrollUpCounterRef = useRef(0);
  const headerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const menuButtonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const mainRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const subNavRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const browserData = useRef<any>({
    wW: 0,
    wH: 0,
    scrollY: 0,
    heightHeader: 0,
    heightMain: 0,
    heightSubNav: 0,
  });
  const inTop100vhRef = useRef(hasHero);

  const [subMenuVisible, setSubMenuVisible] = useState(!isOverlay);
  const [headerTuckUpTransform, setHeaderTuckUpTransform] = useState(
    hasHero ? "translateY(-110%)" : "none"
  );

  const [inTop100Vh, setInTop100Vh] = useState(hasHero);
  const [animating, setAnimating] = useState(false);

  const onResize = useCallback(() => {
    if (isMounted && browserData.current) {
      browserData.current = {
        ...browserData.current,
        wW: window.innerWidth,
        wH: window.innerHeight,
        heightHeader: headerRef?.current?.offsetHeight ?? 0,
        heightMain: mainRef?.current?.offsetHeight + 1 ?? 0,
        heightSubNav: subNavRef?.current?.offsetHeight + 1 ?? 0,
        lastScrollY: 0,
      };
    }
  }, [isMounted]);
  const onResizeDebounced = debounce(onResize, 280);

  useEffect(() => {
    if (!isLoading) {
      setHeaderTuckUpTransform(
        hasHero ? "translateY(-110%) translateZ(0)" : "translateZ(0)"
      );
      setSubMenuVisible(!isOverlay);
    }
  }, [isLoading, hasHero, isOverlay]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("resize", onResizeDebounced);

    onResize();

    const triggerOnResize = () => {
      onResize();
    };
    document.addEventListener("DOMContentLoaded", triggerOnResize);

    return () => {
      window.removeEventListener("resize", onResizeDebounced);
      document.removeEventListener("DOMContentLoaded", triggerOnResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!isMounted) return;
      if (!headerContext.observeScroll || isOverlay) return;

      if (currPos.y < prevPos.y) {
        // scrolling down
        if (scrollUpCounterTimeoutRef.current) {
          clearTimeout(scrollUpCounterTimeoutRef.current);
          scrollUpCounterTimeoutRef.current = null;
          scrollUpCounterRef.current = 0;
        }

        if (scrollUpHideTimeoutRef.current) {
          clearTimeout(scrollUpHideTimeoutRef.current);
          scrollUpHideTimeoutRef.current = null;
        }

        if (
          currPos.y >
          (browserData?.current?.wH - firstSectionOffset) *
            (hasHero ? -1 : -0.25)
        ) {
          // above intro

          if (!inTop100Vh) {
            setInTop100Vh(true);
            inTop100vhRef.current = true;
          }

          if (!animating && hasHero) {
            if (subMenuVisible) {
              setSubMenuVisible(false);
            }
          }
        } else {
          if (inTop100Vh) {
            setInTop100Vh(false);
            inTop100vhRef.current = false;
          }

          if (
            headerTuckUpTransform === "translateZ(0)" ||
            headerTuckUpTransform === "translateY(-110%) translateZ(0)"
          ) {
            setHeaderTuckUpTransform(
              `translateY(-${
                headerRef?.current?.offsetHeight +
                1 -
                subNavRef?.current?.offsetHeight
              }px) translateZ(0)`
            );
          }
        }
      } else {
        // scrolling up
        if (
          currPos.y >
          (browserData?.current?.wH - firstSectionOffset) *
            (hasHero ? -1 : -0.5)
        ) {
          // over intro of half screen
          if (scrollUpCounterTimeoutRef.current) {
            clearTimeout(scrollUpCounterTimeoutRef.current);
            scrollUpCounterTimeoutRef.current = null;
            scrollUpCounterRef.current = 0;
          }

          if (scrollUpHideTimeoutRef.current) {
            clearTimeout(scrollUpHideTimeoutRef.current);
            scrollUpHideTimeoutRef.current = null;
          }

          if (hasHero) {
            if (headerTuckUpTransform !== "translateY(-110%) translateZ(0)") {
              setHeaderTuckUpTransform(`translateZ(0)`);
            }

            if (!animating && !inTop100vhRef.current) {
              setAnimating(true);
              setHeaderTuckUpTransform("translateY(-110%) translateZ(0)");

              animatingTimeoutRef.current = setTimeout(() => {
                setAnimating(false);
                setInTop100Vh(true);
                inTop100vhRef.current = true;
                animatingTimeoutRef.current = null;
              }, 500);
            }
          } else {
            if (!animating && !inTop100vhRef.current) {
              setAnimating(true);
              setHeaderTuckUpTransform("translateZ(0)");

              animatingTimeoutRef.current = setTimeout(() => {
                setAnimating(false);
                setInTop100Vh(true);
                inTop100vhRef.current = true;
                animatingTimeoutRef.current = null;
              }, 500);
            } else if (inTop100vhRef.current) {
              if (headerTuckUpTransform !== "translateZ(0)") {
                setHeaderTuckUpTransform(`translateZ(0)`);
              }
            }
          }
        } else {
          // below intro of half screen
          if (inTop100vhRef.current) {
            setInTop100Vh(false);

            inTop100vhRef.current = false;
          }

          if (animatingTimeoutRef.current) {
            clearTimeout(animatingTimeoutRef.current);
            animatingTimeoutRef.current = null;
            setAnimating(false);
          }

          if (scrollUpCounterTimeoutRef.current)
            clearTimeout(scrollUpCounterTimeoutRef.current);

          scrollUpCounterTimeoutRef.current = setTimeout(() => {
            scrollUpCounterRef.current = 0;
          }, 750);

          scrollUpCounterRef.current += Math.abs(prevPos.y - currPos.y);
          if (scrollUpCounterRef.current > scrollUpPixel) {
            if (headerTuckUpTransform !== "translateZ(0)") {
              setHeaderTuckUpTransform(`translateZ(0)`);
            }
          }

          if (scrollUpHideTimeoutRef.current)
            clearTimeout(scrollUpHideTimeoutRef.current);

          scrollUpHideTimeoutRef.current = setTimeout(() => {
            if (
              headerTuckUpTransform === "translateZ(0)" &&
              !inTop100vhRef.current &&
              !menuContext.getIsOpen()
            ) {
              setHeaderTuckUpTransform(
                `translateY(-${
                  headerRef?.current?.offsetHeight +
                  1 -
                  subNavRef?.current?.offsetHeight
                }px) translateZ(0)`
              );
            }
          }, 1500);
        }
      }
    },
    [
      headerTuckUpTransform,
      isOverlay,
      inTop100Vh,
      animating,
      headerContext.observeScroll,
      isMounted
    ],
    !isOverlay,
    undefined,
    false,
    300
  );

  return (
    <StyledHeader
      ref={headerRef}
      isOverlay={isOverlay}
      headerTransform={headerTuckUpTransform ?? "translateZ(0)"}
      headerPosition={isOverlay ? "absolute" : hasHero ? "fixed" : "sticky"}
      headerColor={!isOverlay ? "var(--ikon-bg-color, #fff)" : "transparent"}
      style={{
        width: menuContext.isOpen ? `calc(100vw - var(--sbw, 0))` : undefined,
      }}
      isHidden={headerContext.fadeOut || isHidden}
      className={`header${hasHero? " with-hero":""}${isOverlay? " overlay":""}`}
    >
      <MainNav ref={mainRef}>
        <BorderContainer
          borderColor={
            isOverlay ? (isLoading ? "#000" : "transparent") : undefined
          }
          isLoading={isLoading && !!!children}          
        >
          {(!hasHero || isOverlay) && <SkipToLink id="content">skip to content</SkipToLink>}
          <Logo />

          <HeaderNav>
            <HeaderNavLinks
              style={{
                transform: "translateZ(0)",
                display: isTabletLandscapeAndUp ? "flex" : "none",
              }}
            >
              {showMainMenu && <MenuHeader id={`menu-header-${isOverlay ? "overlay" : ""}`} />}
            </HeaderNavLinks>

            <HeaderNavButton>
              <MenuButton
                ref={menuButtonRef}
                aria-expanded={menuContext.isOpen}
                aria-label={`${menuContext.isOpen ? "close" : "open"} menu`}
                aria-controls="menu"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault();

                  if (menuContext.isOpen) {
                    menuContext.close();
                  } else {
                    menuContext.open(menuButtonRef);
                  }

                  event.currentTarget.blur();
                }}
              >
                <SvgBackground
                  className="svg open"
                  type="menu"
                  position="left center"
                  width="100%"
                  height="100%"
                />
              </MenuButton>
            </HeaderNavButton>
          </HeaderNav>
        </BorderContainer>
      </MainNav>
      {!isOverlay && !!children && (
        <SubMenu ref={subNavRef} className="subMenu">
          <BorderContainer
            isLoading={isLoading}
            minHeightSize={6}
            alignItems="flex-start"
          >
            {children}
          </BorderContainer>
        </SubMenu>
      )}
    </StyledHeader>
  );
};
