import React from "react";
import styled from "styled-components";
import { useSettingsContext } from "~/providers/SettingsContextProvider";
import { IconLink } from "../ui/IconLink";

const Links = styled.div`
  display: flex;
  justify-items: space-between;
`;

const SLink = styled.div`
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        width: ${props.theme.spacePx(
          breakpoint,
          ["base", "mobile"].includes(breakpoint) ? 6 : 7
        )};
          height: ${props.theme.spacePx(
            breakpoint,
            ["base", "mobile"].includes(breakpoint) ? 6 : 7
          )};
          margin-right: ${props.theme.spacePx(breakpoint, 8)};
          
          &:last-child {
            margin-right: 0;
          }
        `;
    })}
`;

export const SocialMediaIcons = () => {
  const settings = useSettingsContext();

  return (
    <Links>
      {settings?.options?.socialMediaInstagram && (
        <SLink>
          <IconLink
            type="instagram"
            url={settings?.options?.socialMediaInstagram}
            target="_blank"
            rel="noreferral"
            title="Subscribe to our Instagram feed"
          />
        </SLink>
      )}
      {settings?.options?.socialMediaTwitter && (
        <SLink>
          <IconLink
            type="twitter"
            url={settings?.options?.socialMediaTwitter}
            target="_blank"
            rel="noreferral"
            title="View our tweets"
          />
        </SLink>
      )}
      {settings?.options?.socialMediaFacebook && (
        <SLink>
          <IconLink
            type="facebook"
            url={settings?.options?.socialMediaFacebook}
            target="_blank"
            rel="noreferral"
            title="Follow us on Facebook"
          />
        </SLink>
      )}
      {settings?.options?.socialMediaTikTok && (
        <SLink>
          <IconLink
            type="tiktok"
            url={settings?.options?.socialMediaTikTok}
            target="_blank"
            rel="noreferral"
            title="Subscribe to our TikTok channel"
          />
        </SLink>
      )}
      {settings?.options?.socialMediaLinkedIn && (
        <SLink>
          <IconLink
            type="linkedin"
            url={settings?.options?.socialMediaLinkedIn}
            target="_blank"
            rel="noreferral"
            title="Connect with us on LinkedIn"
          />
        </SLink>
      )}
      {settings?.options?.socialMediaYoutube && (
        <SLink>
          <IconLink
            type="youtube"
            url={settings?.options?.socialMediaYoutube}
            target="_blank"
            rel="noreferral"
            title="Watch our videos on Youtube"
          />
        </SLink>
      )}
    </Links>
  );
};
