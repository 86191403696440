import React, { useState } from "react";
import styled from "styled-components";
import Button from "~/components/styled/Button";
import SvgTriangleDown from "~/components/svgs/SvgTriangleDown";
import { Reveal } from "~/components/ui/Reveal";
import { SvgBackground } from "~/components/ui/SvgBackground";
import { useIsBreakpoint } from "~/hooks/useIsBreakpoint";
import { useSettingsContext } from "~/providers/SettingsContextProvider";
import { MenuItem } from "./MenuItem";

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`;

const MenuPagesRow = styled.div`
  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
          margin-top: ${props.theme.spaceFontTop(breakpoint, "h3Heading")};
          margin-bottom: ${props.theme.spaceFontBottom(
            breakpoint,
            7,
            "h3Heading"
          )};
          
        `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
          margin-top: ${props.theme.spaceFontTop(breakpoint, "h3Heading")};
          margin-bottom: calc(${props.theme.spaceFontBottom(
            breakpoint,
            7,
            "h3Heading"
          )} - ${props.theme.spaceFontBottom(breakpoint, 9, "h4Heading")});
          
        `;
    })}
`;
const MenuPagesMain = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
        column-gap: ${props.theme.spacePx(breakpoint, 9)};
        ${props.theme.textStyle(breakpoint, "h2HeadingBold")};
        margin-bottom: ${props.theme.spaceFontBottom(
          breakpoint,
          9,
          "h3Heading"
        )};
      `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
        column-gap: ${props.theme.spacePx(breakpoint, 9)};
        ${props.theme.textStyle(breakpoint, "h3Heading")};
        margin-bottom: ${props.theme.spaceFontBottom(
          breakpoint,
          9,
          "h3Heading"
        )};
      `;
    })}
`;

const MenuPagesSubMenu = styled.div`
  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
          ${props.theme.textStyle(breakpoint, "h3Heading")};
          padding-top: calc( -1 * ${props.theme.spaceFontTop(
            breakpoint,
            "h3Heading"
          )});
          & > span {
            display: block;
            margin-top: ${props.theme.spaceFontTop(breakpoint, "h3Heading")};
            margin-bottom: ${props.theme.spaceFontBottom(
              breakpoint,
              7,
              "h3Heading"
            )};
          }
        `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
          ${props.theme.textStyle(breakpoint, "h4Heading")};
          display: flex;
          flex-wrap:wrap;
          column-gap: ${props.theme.spacePx(breakpoint, 9)};

          & > span {
            display: inline-block;
            margin-top: ${props.theme.spaceFontTop(breakpoint, "h3Heading")};
            margin-bottom: ${props.theme.spaceFontBottom(
              breakpoint,
              9,
              "h4Heading"
            )};
          }
        `;
    })}
`;

const MenuToggle = styled(Button)<{ isOpen: boolean }>`
  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
        width: calc(1.1 * ${props.theme.spacePx(breakpoint, 7)});
        height: calc(1.1 * ${props.theme.spacePx(breakpoint, 7)});
        
      `;
    })}

  transform: ${({ isOpen }) =>
    isOpen ? `translateY(-2px) rotate(180deg)` : `translateY(-1px) `};

  ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }
`;

export const MenuPages = ({ id }: { id: string }) => {
  const settings = useSettingsContext();

  const { isBase, isMobile } = useIsBreakpoint();

  const [isOpenIndexes, setIsOpenIndexes] = useState<number[]>([]);

  return (
    <Nav>
      {!settings?.menus?.pages?.items?.length && (
        <b>
          No menu items in menu &quot;{settings?.menus?.pages?.name ?? id}
          &quot; added
        </b>
      )}
      {settings?.menus?.pages?.items?.length > 0 &&
        settings?.menus?.pages?.items?.map((item: any, index: number) => (
          <MenuPagesRow key={`${id}-${index}`}>
            <MenuPagesMain>
              <MenuItem item={item} />
              <MenuToggle
                isOpen={isOpenIndexes.includes(index)}
                aria-label={
                  isOpenIndexes.includes(index)
                    ? "close sub menu"
                    : "open sub menu"
                }
                onClick={() => {
                  if (isOpenIndexes.includes(index)) {
                    setIsOpenIndexes(
                      isOpenIndexes.filter((item: number) => item !== index)
                    );
                  } else {
                    setIsOpenIndexes([...isOpenIndexes, index]);
                  }
                }}
              >
                <SvgBackground
                  width="100%"
                  height="100%"
                  svg={SvgTriangleDown}
                />
              </MenuToggle>
            </MenuPagesMain>
            {item?.child_items?.length > 0 && (
              <Reveal
                role="menu"
                id={`pages-menu-${index}`}
                active={isBase || isMobile}
                open={isOpenIndexes.includes(index) || (!isBase && !isMobile)}
              >
                <MenuPagesSubMenu>
                  {item?.child_items.map((sItem: any, sIndex: number) => (
                    <MenuItem
                      item={sItem}
                      key={`${id}-sub-${index}-${sIndex}`}
                    />
                  ))}
                </MenuPagesSubMenu>
              </Reveal>
            )}
          </MenuPagesRow>
        ))}
    </Nav>
  );
};
