import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PageMargins } from "~/components/styled/PageMargins";
import { useSettingsContext } from "~/providers/SettingsContextProvider";

import { SocialMediaIcons } from "../ui/SocialMediaIcons";
import { Coin } from "../ui/Coin";
import { MenuFooter } from "./Menus/MenuFooter";
import { MenuMain } from "./Menus/MenuMain";
import { Heading } from "../ui/Heading";
import Button from "../styled/Button";
import InputText from "../styled/InputText";
import { FormFields } from "../forms/FormFields";
import { VisuallyHidden } from "../styled/VisuallyHidden";
import Link from "next/link";
import { LoadingIcon } from "../ui/LoadingIcon";
import { useConfigContext } from "~/providers/ConfigContextProvider";

const Grid = styled.div<{
  topBorder?: boolean;
}>`
  border-top: ${({ topBorder }) => (topBorder ? "1px solid #000;" : "none")};
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "navigation"
    "address"
    "stayintouch"
    "funding"
    "copyright";

  & a {
    text-decoration: none;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          padding-top: ${
            props.topBorder
              ? props.theme.spaceFontTop(breakpoint, "h2Section")
              : 0
          };
          column-gap: ${props.theme.gutterPx(breakpoint)};

          min-height: ${
            ["base", "mobile"].includes(breakpoint)
              ? "auto"
              : props.theme.spacePx(breakpoint, 1)
          }
        `;
    })}

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "navigation navigation"
      "address stayintouch"
      "funding funding"
      "copyright copyright";
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "navigation navigation navigation navigation navigation navigation"
      "address address stayintouch stayintouch stayintouch stayintouch"
      "funding funding funding copyright copyright copyright"
      ;
  }
`;

export const Block = styled.div`
  width: 100%;

  ${(props) =>
    props.theme.apply(["base", "mobile", "tablet"], (breakpoint: string) => {
      return `
          margin-top: ${props.theme.spacePx(breakpoint, 6)};
        `;
    })}

  ${(props) =>
    props.theme.apply(
      ["tabletLandscape", "desktop", "screen"],
      (breakpoint: string) => {
        return `
          margin-top:0;
          margin-bottom: ${props.theme.spacePx(breakpoint, 7)};
        `;
      }
    )}
`;

const Navigations = styled.div`
  grid-area: navigation;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    "nav1 nav2"
    "coin coin";

  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "nav1 nav2 coin";
    grid-template-rows: auto;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap: ${props.theme.gutterPx(breakpoint)};
        row-gap: ${props.theme.gutterPx(breakpoint)};
          padding-top: ${props.theme.spacePx(breakpoint, 8)};
          min-height: ${props.theme.spacePx(
            breakpoint,
            ["base", "mobile"].includes(breakpoint) ? 1 : 2
          )};
          padding-bottom: calc(
            ${props.theme.spacePx(
              breakpoint,
              ["base", "mobile"].includes(breakpoint) ? 6 : 5
            )}
            - ${props.theme.spacePx(breakpoint, 8)}
          );
        `;
    })}
`;

const Address = styled.div`
  grid-area: address;

  & p {
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
          margin-bottom: ${props.theme.spaceFontBottom(breakpoint, 7, "body")};
        `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
            margin-bottom: ${props.theme.spacePx(breakpoint, 9)};

            > p:last-child {
              margin-bottom: 0px;
            }
          `;
    })}
`;

const CoinContainer = styled.div`
  grid-area: coin;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Social = styled.div`
  display: flex;
  grid-area: social;
  justify-content: space-between;

  flex-direction: column;

  ${(props) =>
    props.theme.apply(["base", "mobile", "tablet"], (breakpoint: string) => {
      return `
          margin-bottom: ${props.theme.spaceFontBottom(breakpoint, 7, "body")};
        `;
    })}

  ${({ theme }) => theme.breakpoints.tablet} {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const Copyright = styled.div`
  grid-area: copyright;

  & p {
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.theme.apply(["base", "mobile", "tablet"], (breakpoint: string) => {
      return `
          margin-top: ${props.theme.spacePx(breakpoint, 6)};
        `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
          margin-top: ${props.theme.spacePx(breakpoint, 6)};
        `;
    })}

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${props.theme.textStyle(breakpoint, "caption")};
        `;
    })}

  ${({ theme }) => theme.breakpoints.desktop} {
    align-self: end;
    justify-self: end;
  }
`;

const Funding = styled.div`
  grid-area: funding;

  & img {
    max-width: 100%;
    height: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > aside {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
        > aside {
          padding-top: ${props.theme.spacePx(breakpoint, 8)};
          grid-column-gap: ${props.theme.spacePx(breakpoint, 8)};
        }
        margin-top: ${props.theme.spacePx(breakpoint, 7)};
      `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "tabletLandscape", "desktop", "screen"], (breakpoint: string) => {
      return `
        > aside {
          padding-top: 0;
          grid-column-gap: ${props.theme.spacePx(breakpoint, 8)};
        }
        margin-top: ${props.theme.spacePx(breakpoint, 7)};
      `;
    })}

  ${({ theme }) => theme.breakpoints.tablet} {
    > aside {
      width: 80%;
      max-width: 600px;
    }

    > p {
      width: 80%;
      max-width: 600px;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${props.theme.textStyle(breakpoint, "caption")};
        `;
    })}
`;

const StayInTouch = styled.div`
  grid-area: stayintouch;
  width: 100%;

  ${(props) =>
    props.theme.apply(
      ["tabletLandscape", "desktop", "screen"],
      (breakpoint: string) => {
        return `
        column-gap: ${props.theme.spacePx(breakpoint, 7)};  
      `;
      }
    )}
`;

const FormContainer = styled.div`
  width: 100%;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        padding-top: ${props.theme.spacePx(breakpoint, 9)};
        padding-bottom: ${props.theme.spacePx(breakpoint, 7)};
        p {
          margin-bottom: ${props.theme.spacePx(breakpoint, 7)};
        }
      `;
    })}
`;

const NewsletterInput = styled(InputText)<{ isError: boolean }>`
  color: ${({ isError }) =>
    isError ? "var(--ikon-error-color, #c00)" : "#000"};
  min-width: 50vw;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: ${props.theme.spacePx(breakpoint, 6)};
        ${props.theme.textStyle(breakpoint, `h3Heading`)};
        text-transform: none;
        padding-top: ${props.theme.fontValignPx(breakpoint, "h3Heading")};
        padding-left: ${props.theme.spacePx(breakpoint, 9)};
        padding-right: ${props.theme.spacePx(breakpoint, 9)};
        `;
    })}

  ${(props) =>
    props.theme.apply(
      ["tabletLandscape", "desktop", "screen"],
      (breakpoint: string) => {
        return `
          min-width: 25vw;
        `;
      }
    )}
`;

const NewsletterButton = styled(Button)<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ isLoading }) =>
    isLoading
      ? "var(--ikon-hl-color, #ff0) !important"
      : "var(--ikon-hl-color, #ff0)"};
  color: ${({ isLoading }) =>
    isLoading ? "var(--ikon-hl-color, #ff0) !important" : "#000"};
  transition: background-color 0.3s, color 0.3s;

  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "all")};

  @media (any-pointer: fine) {
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: ${props.theme.spacePx(breakpoint, 6)};
        ${props.theme.textStyle(breakpoint, `h3Heading`)};
        padding:0 ${props.theme.spacePx(
          breakpoint,
          9
        )} 0.1em  ${props.theme.spacePx(breakpoint, 9)};
      `;
    })}
`;

const Form = styled.div``;

const Links = styled.div``;

const Message = styled.div<{ isError?: boolean }>`
  color: ${({ isError }) =>
    isError ? "var(--ikon-error-color, #c00)" : "#000"};
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        margin-bottom:${props.theme.spacePx(breakpoint, 7)}; 
        .form-field.consent {
          margin-top:${props.theme.spacePx(breakpoint, 8)};          
        }
        `;
    })}
`;

const StyledFormFields = styled(FormFields)`
  .text {
    display: flex;
  }

  .error-message {
    display: block;
    width: 100%;
  }
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        
        .form-field.consent {
          margin-top:${props.theme.spacePx(breakpoint, 8)};
          .checkboxes {
            .gfield_consent_label:first-of-type {
              width: calc(100% - 5px - ${props.theme.spacePx(breakpoint, 8)});
            }
          }
        }
        `;
    })}
`;

export const Footer = () => {
  const settings = useSettingsContext();
  const config = useConfigContext();
  const hiddenFieldRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [formState, setFormState] = useState<any>({
    errors: {
      email: true,
      consent: true,
    },
    touched: {
      email: false,
      consent: false,
    },
    submitting: false,
    triedToSubmit: false,
    submitted: false,
    submitError: false,
    slowDown: false,
  });

  useEffect(() => {
    let isMounted = true;
    if (!hiddenFieldRef?.current) return;

    hiddenFieldRef.current.value = "bad-name";
    let t: ReturnType<typeof setTimeout> | null = setTimeout(() => {
      t = null;
      if (isMounted && hiddenFieldRef.current) {
        hiddenFieldRef.current.value = "good-name";
      }
    }, 6666);

    return () => {
      isMounted = false;
      if (t) clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageMargins spaceBottom={7}>
      <Grid topBorder={true}>
        <Navigations>
          <MenuMain id="menu-main-in-footer" />
          <MenuFooter id="menu-footer" />
          <CoinContainer>
            <Coin />
          </CoinContainer>
        </Navigations>

        <StayInTouch>
          <Social>
            <Block>
              <div>
                <Heading heading="h4" space={8}>
                  STAY IN TOUCH
                </Heading>

                <Links>
                  <SocialMediaIcons />
                </Links>
              </div>
            </Block>
          </Social>
          <FormContainer>
            <Block>
              <div>
                <Heading heading="h4" space={8}>
                  SUBSCRIBE TO OUR NEWSLETTER
                </Heading>
                <Form>
                  {formState.submitted && (
                    <Message>
                      <b>THANK YOU!</b>
                      <br />
                      Please check your inbox for a confirmation email and
                      verify your email address to subscribe.
                    </Message>
                  )}

                  {formState.submitError && (
                    <Message isError>
                      Unfortunately we could not process your subscription
                      request. Please ensure your email address is entered with
                      all lower case letters and try again.
                      <br />
                      Many thanks.
                    </Message>
                  )}

                  {formState.slowDown && (
                    <Message isError>
                      <b>Ooops!</b>
                      <br />
                      You&#39;re very quick. Are you a bot? No? Please wait a
                      few seconds and then try it again.
                    </Message>
                  )}

                  {!formState.submitted && !formState.submitError && (
                    <form
                      noValidate
                      autoComplete="new-email"
                      action="#"
                      onSubmit={async (event) => {
                        event.preventDefault();

                        if (hiddenFieldRef.current.value === "bad-name") {
                          setFormState({
                            ...formState,
                            slowDown: true,
                          });
                          return;
                        }

                        if (
                          !formState.touched.consent ||
                          !formState?.touched?.consent
                        ) {
                          setFormState({
                            ...formState,
                            touched: {
                              email: true,
                              consent: true,
                            },
                            triedToSubmit: true,
                            errors: {
                              email: !formState?.touched?.email
                                ? true
                                : formState?.errors?.email,
                              consent: !formState?.touched?.consent
                                ? true
                                : formState?.errors?.consent,
                            },
                            slowDown: false,
                          });
                        } else {
                          if (
                            formState?.errors?.email ||
                            formState?.errors?.consent
                          ) {
                            setFormState({
                              ...formState,
                              touched: {
                                email: true,
                                consent: true,
                              },
                              triedToSubmit: true,
                              slowDown: false,
                            });
                          } else {
                            setFormState({
                              ...formState,
                              submitError: false,
                              submitted: false,
                              submitting: true,
                              triedToSubmit: true,
                              slowDown: false,
                            });

                            const body: any = {};

                            Object.keys((event.target as any).elements).forEach(
                              (key) => {
                                let element = (event.target as any)[key];
                                if (element.type !== "submit") {
                                  body[element.name] =
                                    key === "fluxed-mc-e"
                                      ? `${element.value}`.toLowerCase()
                                      : element.value;
                                }
                              }
                            );

                            const result = await fetch(
                              `${config.apiUrl}/ikon/v1/newsletter-subscribe`,
                              {
                                method: "POST",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  ...body,
                                  secret: config.newsletterSecret,
                                }),
                              }
                            )
                              .then((response) => response.json())
                              .then((data) => data);

                            if (result?.status === 200) {
                              setFormState({
                                ...formState,
                                submitError: false,
                                submitted: true,
                                submitting: false,
                              });
                            } else {
                              setFormState({
                                ...formState,
                                submitError: true,
                                submitted: false,
                                submitting: false,
                              });
                            }
                          }
                        }
                      }}
                    >
                      <StyledFormFields>
                        <div className="form-fields">
                          <div
                            className={`form-field${
                              !!formState?.touched?.email &&
                              !!formState?.errors?.email
                                ? " error"
                                : ""
                            }`}
                          >
                            <input
                              type="hidden"
                              name="name"
                              ref={hiddenFieldRef}
                            />
                            <div className="text">
                              <VisuallyHidden>
                                <label htmlFor="fuxed_mc_1">
                                  Email address <abbr>required</abbr>
                                </label>
                              </VisuallyHidden>
                              <NewsletterInput
                                id="fuxed_mc_1"
                                name="fluxed-mc-e"
                                type="email"
                                placeholder="your@email.com"
                                required={true}
                                aria-label="email address"
                                aria-describedby="fluxed-mc-1-e"
                                aria-invalid={
                                  !!formState?.touched?.email &&
                                  !!formState?.errors?.email
                                }
                                autoComplete="none"
                                isError={
                                  !!formState?.touched?.email &&
                                  !!formState?.errors?.email
                                }
                                onChange={(event) => {
                                  if (formState.submitting) return;
                                  setFormState({
                                    ...formState,
                                    touched: {
                                      ...formState.touched,
                                      email: true,
                                    },
                                    errors: {
                                      ...formState.errors,
                                      email:
                                        !event.target.checkValidity() ||
                                        event.target.value.split("@").length !=
                                          2 ||
                                        (event.target.value.split("@").length >
                                          1 &&
                                          event.target.value
                                            .split("@")[1]
                                            .indexOf(".") === -1),
                                    },
                                  });
                                }}
                              />
                              <NewsletterButton
                                type="submit"
                                disabled={formState.submitting}
                                isLoading={formState.submitting}
                              >
                                {formState.submitting && (
                                  <LoadingIcon isLoading size={8} />
                                )}
                                Subscribe
                              </NewsletterButton>
                            </div>
                            {formState.triedToSubmit &&
                              !!formState?.touched?.email &&
                              !!formState?.errors?.email && (
                                <span
                                  className="error-message"
                                  id="fluxed-mc-1-e"
                                >
                                  Please enter your email address
                                </span>
                              )}
                          </div>
                          <div
                            className={`form-field consent${
                              !!formState?.touched?.consent &&
                              !!formState?.errors?.consent
                                ? " error"
                                : ""
                            }`}
                          >
                            <div className="checkboxes">
                              <input
                                id="fuxed_mc_2_1"
                                type="checkbox"
                                name="fluxed-mc-c"
                                required={true}
                                aria-invalid={
                                  !!formState?.touched?.consent &&
                                  !!formState?.errors?.consent
                                }
                                value="1"
                                onChange={(event) => {
                                  if (formState.submitting) return;

                                  setFormState({
                                    ...formState,
                                    touched: {
                                      ...formState.touched,
                                      consent: true,
                                    },
                                    errors: {
                                      ...formState.errors,
                                      consent: !event.target.checkValidity(),
                                    },
                                  });
                                }}
                              />

                              <label
                                className="gfield_consent_label"
                                htmlFor="fuxed_mc_2_1"
                              >
                                I CONSENT TO RECEIVE EMAILS FROM IKON
                                <VisuallyHidden>required</VisuallyHidden>
                              </label>

                              <div className="description">
                                PLEASE READ OUR{" "}
                                <Link href="/legal-policy/" passHref>
                                  <a>PRIVACY POLICY</a>
                                </Link>{" "}
                                TO LEARN HOW WE HANDLE YOUR DATA
                              </div>
                            </div>
                          </div>
                        </div>
                      </StyledFormFields>
                    </form>
                  )}
                </Form>
              </div>
            </Block>
          </FormContainer>
        </StayInTouch>
        <Address
          dangerouslySetInnerHTML={{
            __html: settings?.options?.menuContactInformation ?? "",
          }}
        />

        <Funding>
          <p>
            Ikon is supported using public funding by the National Lottery
            through Arts Council England, and Birmingham City Council.
          </p>
          <aside>
            <a
              href="https://www.artscouncil.org.uk/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/img/ace.png" alt="Logo Arts Council England" />
            </a>
            <a
              href="https://www.birmingham.gov.uk/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/img/bcc.png" alt="Logo Birmingham City Council" />
            </a>
          </aside>
        </Funding>
        <Copyright
          dangerouslySetInnerHTML={{
            __html: settings?.options?.copyrightNotice ?? "",
          }}
        />
      </Grid>
    </PageMargins>
  );
};
