import styled from "styled-components";

export const Button = styled.button`
  font-family: ABC Diatype,Arial,Helvetica,sans-serif;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;
  appearance: none;
  user-select: none;
  color: #000;
`;

export default Button;
